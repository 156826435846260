import * as React from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SiteContainer from "../../components/site-container";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";

export function Terms() {
    return (
        <div>
            <Link to="/terms/">Terms</Link>
        </div>
    )
}

const TermsPage = () => {
    return (
        <SiteContainer>
            <Helmet>
                <title>Firstparty - Terms and Conditions</title>
                
            </Helmet>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <div>
                            <h1>First Party Labs Inc. Website Terms of Use</h1>
                            <p>The Firstparty website located at https://firstpartyhq.com/ (the “Site”) is a copyrighted work belonging to First Party Labs, Inc., (“Firstparty”, “us”, “our”, and “we”).</p>
                            <p>Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features.  All such additional terms, guidelines, and rules are incorporated by reference into these Terms of Use (collectively, the “Terms”).  Your access to and use of the Site is also subject to our <Link to="/privacy/"> Privacy Policy</Link>.</p>
                            <p>Firstparty also provides a proprietary website and app analytics platform, (the “Firstparty Platform”). Access to the Firstparty Platform is subject to the terms and conditions of the Platform agreement between Firstparty and each of our customers. </p>
                            <p>THESE TERMS SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SITE.  BY ACCESSING OR USING THE SITE, YOU ARE ACCEPTING THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT).  YOU MAY NOT ACCESS OR USE THE SITE OR ACCEPT THESE TERMS IF YOU ARE NOT AT LEAST 18 YEARS OLD.  IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THESE TERMS, DO NOT ACCESS AND/OR USE THE SITE.</p>
                            <p>THESE TERMS REQUIRE THE USE OF ARBITRATION (SECTION 11.2) ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.</p>
                            <br></br>
                            <h2>1.	ACCESS TO THE SITE</h2>
                            <p><strong>1.1 License.</strong> Subject to these Terms, Firstparty grants you a non-transferable, non-exclusive, revocable, limited license to use and access the Site solely for your own personal or internal business use.</p>
                            <p><strong>1.2 Certain Restrictions.</strong> The rights granted to you in these Terms are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site, whether in whole or in part, or any content displayed on the Site;  (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site in order to build a similar or competitive website, product, or service; and (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means.  Unless otherwise indicated, any future release, update, or other addition to the functionality of the Site shall be subject to these Terms.  All copyright and other proprietary notices on the Site (or on any content displayed on the Site) must be retained on all copies thereof.</p>
                            <p><strong>1.3 Modification.</strong> Firstparty reserves the right, at any time, to modify, suspend, or discontinue the Site (in whole or in part) with or without notice to you.  You agree that Firstparty will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Site or any part thereof.</p>
                            <p><strong>1.4 No Support or Maintenance.</strong> You acknowledge and agree that Firstparty will have no obligation to provide you with any support or maintenance in connection with the Site.</p>
                            <p><strong>1.5 Ownership.</strong> You acknowledge that all intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Site and its content are owned by Firstparty or Firstparty’s suppliers.  Neither these Terms (nor your access to the Site) transfers to you or any third party any rights, title or interest in or to such intellectual property rights, except for the limited access rights expressly set forth in Section 1.1.  Firstparty and its suppliers reserve all rights not granted in these Terms.  There are no implied licenses granted under these Terms.</p>
                            <p><strong>1. ACCEPTABLE USE POLICY</strong> The following terms constitute our <strong>“Acceptable Use Policy”</strong>: You agree not to: (i) upload, transmit, or distribute to or through the Site any computer viruses, worms, or any software intended to damage or alter a computer system or data;  (ii) interfere with, disrupt, or create an undue burden on servers or networks connected to the Site, or violate the regulations, policies or procedures of such networks;  (iii) attempt to gain unauthorized access to the Site (or to other computer systems or networks connected to or used together with the Site), whether through password mining or any other means;  (iv) interfere with any other user’s use and enjoyment of the Site;  or (v) use software or automated agents or scripts to produce multiple accounts on the Site, or to generate automated searches, requests, or queries to (or to strip, scrape, or mine data from) the Site (provided, however, that we conditionally grant to the operators of public search engines revocable permission to use spiders to copy materials from the Site for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the materials, but not caches or archives of such materials, subject to the parameters set forth in our robots.txt file).</p>
                            <h2>2. ENFORCEMENT</h2>
                            <p>We reserve the right (but have no obligation) to investigate and/or take appropriate action against you in our sole discretion if you violate the Acceptable Use Policy or any other provision of these Terms or otherwise create liability for us or any other person.  Such action may include reporting you to law enforcement authorities.</p>
                            <h2>3. FEEDBACK</h2>
                            <p>If you provide Firstparty with any feedback or suggestions regarding the Site (“<strong>Feedback</strong>”), you hereby assign to Firstparty all rights in such Feedback and agree that Firstparty shall have the right to use and fully exploit such Feedback and related information in any manner it deems appropriate.  Firstparty will treat any Feedback you provide to Firstparty as non-confidential and non-proprietary.  You agree that you will not submit to Firstparty any information or ideas that you consider to be confidential or proprietary</p>
                            <h2>4. FIRSTPARTY COMMUNICATIONS</h2>
                            <p><strong>1.6 Generally</strong> You may have the opportunity to provide us with your e-mail address.  By providing your email address to us, you consent to receiving email communications from Firstparty.  Communications from us and our current or future affiliated companies may include communications about your use of the Site, updates concerning new and existing features on the Site, and news concerning Firstparty and industry developments.  You will have the ability to opt out of receiving such communications by following the unsubscribe instructions in the communication itself.  YOU ACKNOWLEDGE THAT YOU ARE NOT REQUIRED TO CONSENT TO RECEIVE PROMOTIONAL EMAILS AS A CONDITION OF USING THE SITE.</p>
                            <p><strong>1.7 Electronic Communications</strong> The communications between you and Firstparty use electronic means, whether you use the Site or send us emails, or whether Firstparty posts notices on the Site or communicates with you via email.  For contractual purposes, you (a) consent to receive communications from Firstparty in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Firstparty provides to you electronically satisfy any legal requirement that such communications would satisfy if they were to be in a hardcopy writing.  The foregoing does not affect your non-waivable rights.</p>
                            <h2>5. INDEMNIFICATION</h2>
                            <p>You agree to indemnify and hold Firstparty (and its officers, employees, and agents) harmless, including costs and attorneys’ fees, from any claim or demand due to or arising out of (a) your use of the Site; (b) your violation of these Terms; or (c) your violation of applicable laws or regulations.  Firstparty reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims.  You agree not to settle any matter without the prior written consent of Firstparty.  Firstparty will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</p>
                            <h2>6. THIRD-PARTY LINKS AND APPLICATIONS;  RELEASE</h2>
                            <p><strong>1.8 Third-Party Links and Applications.</strong>The Site may contain links to third-party websites and services, and applications for third parties (collectively, “Third-Party Links and Applications”).  Such Third-Party Links and Applications are not under the control of Firstparty, and Firstparty is not responsible for any Third-Party Links and Applications.  Firstparty provides access to these Third-Party Links and Applications only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Links and Applications.  You use all Third-Party Links and Applications at your own risk, and should apply a suitable level of caution and discretion in doing so.  When you click on any of the Third-Party Links and Applications, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices.  You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction in connection with such Third-Party Links and Applications.</p>
                            <p><strong>1.9 Release</strong> You hereby release and forever discharge Firstparty (and our officers, employees, agents, successors, and assigns) from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature (including personal injuries, death, and property damage), that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Site (including interactions with any other Site users or any Third-Party Links and Applications).  If you are a California resident, you hereby waive California Civil Code 1542 in connection with the foregoing, which states, “A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.”</p>
                            <h2>7. DISCLAIMERS</h2>
                            <p>THE SITE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS AND NO GUARANTEES REGARDING OUTCOMES OR PERFORMANCE.  FIRSTPARTY (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT.  WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE SITE WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE.  IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE SITE, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE.</p>
                            <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.  SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</p>
                            <h2>8. LIMITATION ON LIABILITY</h2>
                            <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL FIRSTPARTY (OR OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, THE SITE, EVEN IF FIRSTPARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  ACCESS TO, AND USE OF, THE SITE IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM.</p>
                            <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THESE TERMS (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF FIFTY US DOLLARS (U.S.  $50.00).  THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT.  YOU AGREE THAT OUR SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THESE TERMS.</p>
                            <p>SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>
                            <h2>9. TERM AND TERMINATION</h2>
                            <p>Subject to this Section, these Terms will remain in full force and effect while you use the Site.  We may suspend or terminate your rights to access or use the Site at any time for any reason at our sole discretion, including for any use of the Site in violation of these Terms.  Upon termination of your rights under these Terms, your right to access and use the Site will terminate immediately.  Firstparty will not have any liability whatsoever to you for any suspension or termination of your rights under these Terms.  Even after your rights under these Terms are terminated, the following provisions of these Terms will remain in effect:  Sections 1.2 through 11.</p>
                            <h2>10. GENERAL</h2>
                            <p><strong>1.10 Changes.</strong> These Terms are subject to occasional revision, and if we make any substantial changes, we may notify you by sending you an e-mail to the last e-mail address you provided to us (if any), and/or by prominently posting notice of the changes on our Site.  You are responsible for providing us with your most current e-mail address.  In the event that the last e-mail address that you have provided us is not valid, or for any reason is not capable of delivering to you the notice described above, our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice.  Any changes to these Terms will be effective upon the earlier of thirty (30) calendar days following our dispatch of an e-mail notice to you (if applicable) or thirty (30) calendar days following our posting of notice of the changes on our Site.  These changes will be effective immediately for new users of our Site.  Continued use of our Site following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.</p>
                            <p><strong>1.11 Dispute Resolution.  PLEASE READ THIS CAREFULLY.  IT AFFECTS YOUR RIGHTS.</strong></p>
                            <p>(a) Any and all controversies, disputes, demands, counts, claims, or causes of action (including the interpretation and scope of this clause, and the arbitrability of the controversy, dispute, demand, count, claim, or cause of action) between you and Firstparty and our employees, agents, successors, or assigns, regarding or relating to the Site or these Terms shall exclusively be settled through binding and confidential arbitration.</p>
                            <p>(b) Arbitration shall be subject to the Federal Arbitration Act and not any state arbitration law.  The arbitration shall be conducted before one commercial arbitrator with substantial experience in resolving commercial contract disputes from the American Arbitration Association (“AAA”) or JAMS.  As modified by these Terms, and unless otherwise agreed upon by the parties in writing, the arbitration will be governed by the AAA’s or JAMS’s rules for commercial arbitration and, if the arbitrator deems them applicable, the procedures for consumer-related disputes.</p>
                            <p>(c) You are thus GIVING UP YOUR RIGHT TO GO TO COURT to assert or defend your rights EXCEPT for matters that may be taken to small claims court.  Your rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge or jury.  You are entitled to a FAIR HEARING, BUT the arbitration procedures are SIMPLER AND MORE LIMITED THAN RULES APPLICABLE IN COURT.  Arbitrator decisions are as enforceable as any court order and are subject to VERY LIMITED REVIEW BY A COURT.</p>
                            <p>(d) You and we must abide by the following rules: (1) ANY CLAIMS BROUGHT BY YOU OR US MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING;  (2) THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS, MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING, AND MAY NOT AWARD CLASS-WIDE RELIEF;  (3) in the event that you are able to demonstrate that the costs of arbitration will be prohibitive as compared to costs of litigation, we will pay as much of your filing and hearing fees in connection with the arbitration as the arbitrator deems necessary to prevent the arbitration from being cost-prohibitive as compared to the cost of litigation;  (4) we also reserve the right in our sole and exclusive discretion to assume responsibility for all of the costs of the arbitration;  (5) the arbitrator shall honor claims of privilege and privacy recognized at law;  (6) the arbitrator’s award shall be final and may be enforced in any court of competent jurisdiction;  (7) the arbitrator may award any individual relief or individual remedies that are permitted by applicable law;  and (8) each side pays its own attorneys’ fees and expenses unless there is a statutory provision that requires the prevailing party to be paid its fees’ and litigation expenses, and then in such instance, the fees and costs awarded shall be determined by the applicable law.</p>
                            <p>(e) Notwithstanding the foregoing, either you or we may bring an individual action in small claims court.  Further, claims of infringement or misappropriation of the other party’s patent, copyright, trademark, or trade secret shall not be subject to this arbitration agreement.  Such claims shall be exclusively brought in the state or federal courts located in Scottsdale, Arizona.  Additionally, notwithstanding this agreement to arbitrate, either party may seek emergency equitable relief before the state or federal courts located in Scottsdale, Arizona in order to maintain the status quo pending arbitration, and hereby agree to submit to the exclusive personal jurisdiction of the courts located within Scottsdale, Arizona for such purpose.  A request for interim measures shall not be deemed a waiver of the right to arbitrate.</p>
                            <p>(f) With the exception of subparts (1) and (2) in Section 11.2(d) above (prohibiting arbitration on a class or collective basis), if any part of this arbitration provision is deemed to be invalid, unenforceable or illegal, or otherwise conflicts with the Agreement, then the balance of this arbitration provision shall remain in effect and shall be construed in accordance with its terms as if the invalid, unenforceable, illegal or conflicting provision were not contained herein.  If, however, either subparts (1) and (2) in Section 11.2(d) (prohibiting arbitration on a class or collective basis) is found to be invalid, unenforceable or illegal, then the entirety of this arbitration provision shall be null and void, and neither you nor we shall be entitled to arbitration.  If for any reason a claim proceeds in court rather than in arbitration, the dispute shall be exclusively brought in state or federal court in Scottsdale, Arizona.</p>
                            <p>(g) Notwithstanding any provision in this Agreement to the contrary, if we seek to terminate the Dispute Resolution section as included in these Terms, any such termination shall not be effective until 30 days after the version of these Terms not containing the agreement to arbitrate is posted to the Site, and shall not be effective as to any claim of which you provided Firstparty with written notice prior to the date of termination.</p>
                            <p>(h) For more information on AAA, its Rules and Procedures, and how to file an arbitration claim, you may call AAA at 800-778-7879 or visit the AAA website at http://www.adr.org.  For more information on JAMS, it’s Rules and Procedures, and how to file an arbitration claim, you may call JAMS at 800-352-5267 or visit the JAMS website at http://www.jamsadr.com.</p>
                            <p>(i) Any and all controversies, disputes, demands, counts, claims, or causes of action between you and Firstparty and our employees, agents, successors, or assigns, regarding or relating to these Terms or the Site shall exclusively be governed by the internal laws of the State of Arizona, without regard to its choice of law rules and without regard to conflicts of laws principles except that the arbitration provision shall be governed by the Federal Arbitration Act.  The United Nations Convention on Contracts for the International Sale of Goods shall not apply to these Terms. </p>
                            <p><strong>1.12 Export</strong> The Site may be subject to U.S.  export control laws and may be subject to export or import regulations in other countries.  You agree not to export, reexport, or transfer, directly or indirectly, any U.S.  technical data acquired from Firstparty, or any products utilizing such data, in violation of the United States export laws or regulations.</p>
                            <p><strong>1.13 Disclosures</strong> Firstparty is located at the address in Section 11.7.  If you are a California resident, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Product of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.</p>
                            <p><strong>1.14 Entire Terms.</strong> These Terms constitute the entire agreement between you and us regarding use of the Site.  Our failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision.  The section titles in these Terms are for convenience only and have no legal or contractual effect.  The word “including” means “including without limitation.”  If any provision of these Terms is, for any reason, held to be invalid or unenforceable, the other provisions of these Terms will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law.  These Terms, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Firstparty’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void.  Firstparty may freely assign these Terms.  The terms and conditions set forth in these Terms shall be binding upon assignees.</p>
                            <p><strong>1.15 Copyright/Trademark Information.</strong> Copyright © 2021, First Party Labs, Inc.  All rights reserved.  All trademarks, logos and service marks (“Marks”) displayed on the Site are our property or the property of other third parties.  You are not permitted to use these Marks without our prior written consent or the consent of such third party which may own the Marks.  All goodwill generated from the use of any Firstparty Trademark will inure to Firstparty’s benefit</p>
                            <p><strong>1.16 Contact Information:</strong></p>
                            <p>First Party Labs, Inc.</p>
                            <p>4400 N Scottsdale Rd</p>
                            <p>Ste 9 #701</p>
                            <p>Scottsdale, AZ 85251</p>
                            <p>support@firstpartyhq.com</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </SiteContainer>
    )
}

export default TermsPage
